<template>
  <nav :class="['pcoded-navbar', 'noprint', { 'mob-open': isOpen }]">
    <div class="navbar-wrapper">
      <div class="navbar-brand header-logo">
        <a href="#" @click.prevent="$router.push({ name: 'dashboard' })" class="b-brand">
          <span class="b-title">Dashboard<br><span style="font-size:12px;">iMessage API</span></span>
        </a>
      </div>
      <div class="navbar-content scroll-div">
        <div class="navbar-content_between">
          <ul class="nav pcoded-inner-navbar">
            <router-link :to="{ name: 'dashboard' }" v-slot="{ isActive }">
              <li :class="['nav-item', {'active': isActive}]">
                <a class="nav-link">
                <span class="pcoded-micon">
                  <i class="feather icon-home"></i>
                </span>
                  <span class="pcoded-mtext">{{ $t('home') }}</span>
                </a>
              </li>
            </router-link>
<!--            <li class="nav-item pcoded-menu-caption">-->
<!--              <label>{{ $tc('license', 2) }}</label>-->
<!--            </li>-->
              <template
                v-for="item in submenuItems(license)"
                :key="item.name"
              >
                <li
                  v-if="item.children && item.isShow"
                  :class="['nav-item pcoded-hasmenu', {'pcoded-trigger': manuallyOopenedSection === item.name || (pathBasedSection && pathBasedSection.name === item.name) }]"
                >
                  <a class="nav-link" @click.prevent="toggleMenuItem(item.name)">
                    <span class="pcoded-micon">
                      <i :class="['feather', item.icon]"></i>
                    </span>
                    <span class="pcoded-mtext">{{ $t(item.title) }}</span>
                  </a>
                  <ul :class="['pcoded-submenu', { 'active': manuallyOopenedSection === item.name || (pathBasedSection && pathBasedSection.name === item.name) }]" >
                    <template
                      v-for="subLink of item.children"
                      :key="subLink.name || subLink.title"
                    >
                      <li
                        v-if="subLink.isShow && subLink.externalLink"
                        :class="{'active': isExactActive}"
                      >
                        <a :href="subLink.externalLink" target="_blank">
                          {{ $t(subLink.title) }}
                          <span class="pcoded-micon">
                            <i class="feather icon-external-link"></i>
                          </span>
                        </a>
                      </li>
                      <router-link
                        v-if="subLink.isShow && subLink.name"
                        :to="{ name: subLink.name }"
                        @click="$emit('close')"
                        v-slot="{ href, isExactActive }"
                        tag="div"
                      >
                        <li :class="{'active': isExactActive}">
                          <a :href="href">{{ $t(subLink.title) }}</a>
                        </li>
                      </router-link>
                    </template>
                  </ul>
                </li>
                <template v-else>
                  <router-link
                    v-if="item.isShow"
                    :to="{ name: item.name }"
                    @click="$emit('close')"
                    v-slot="{ href, isActive }"
                    tag="div"
                  >
                    <li :class="['nav-item', {'active': isActive}]">
                      <a class="nav-link" :href="href">
                        <span v-if="item.icon" class="pcoded-micon">
                          <i :class="['feather', item.icon]"></i>
                        </span>
                        <span class="pcoded-mtext">{{ $t(item.title) }}</span>
                      </a>
                    </li>
                  </router-link>
                </template>
              </template>
          </ul>
          <reset-pin></reset-pin>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { computed, ref, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'SidebarMenu',
  components: {
    'reset-pin': defineAsyncComponent(() => import('@/application/components/elements/ResetPin')),
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  emits: ['close'],
  setup() {
    const store = useStore()
    const router = useRouter()
    const menuItem = ref([])
    const isLoad = ref(true)
    const license = computed(() => store.getters.getLicense)

    const helpdeskPage = process.env.VUE_APP_HELPDESK_PAGE
    const developerDocsPage = process.env.VUE_APP_DEVELOPER_DOCS_PAGE
    const releaseNotesPage = process.env.VUE_APP_RELEASE_NOTES_PAGE
    const openSupportTicketPage = process.env.VUE_APP_OPEN_SUPPORT_TICKET_PAGE
    const openInvoiceHistory = process.env.VUE_APP_INVOICE_HISTORY
    const openReleaseNotes = process.env.VUE_APP_RELEASE_NOTES
    const showSupportAndDocsPage = !!helpdeskPage || !!developerDocsPage || !!openSupportTicketPage || !!releaseNotesPage || !!openInvoiceHistory || !!openReleaseNotes

    const submenuItems = () => [
      {
        name: 'messaging',
        title: 'conversationApiTitle',
        isShow: true,
        icon: 'icon-message-circle',
        children: [
          {
            name: 'show-sender-names',
            title: 'currentSenderNames',
            isShow: true,
          },
          {
            name: 'messaging',
            title: 'messaging',
            isShow: true,
          },
          {
            name: 'integrations',
            title: 'integrationsMenu',
            isShow: false,
          },
          {
            name: 'sent-messages',
            title: 'sentMessagesTitle',
            isShow: true,
          },
          {
            name: 'inbound-messages',
            title: 'inboundMessages',
            isShow: true,
          },
          {
            name: 'conversation-api-settings',
            title: 'conversationApiSettings',
            isShow: true,
          },
        ],
      },
      {
        name: 'lookup',
        title: 'lookupApiTitle',
        isShow: true,
        icon: 'icon-smartphone',
        children: [
          {
            name: 'lookup',
            title: 'lookupMenu',
            isShow: true,
          },
          {
            name: 'lookup-history',
            title: 'lookupHistoryMenu',
            isShow: true,
          },
          {
            name: 'lookup-bulk-history',
            title: 'lookupHistoryDetailsMenu',
            isShow: true,
          },
          {
            name: 'lookup-api-settings',
            title: 'lookupApiSettings',
            isShow: true,
          },
        ],
      },
      {
        name: 'imessage-auth-settings',
        title: 'imessageMenu',
        isShow: true,
        icon: 'icon-log-in',
        children: [
          {
            name: 'imessage-auth-platforms',
            title: 'iMessageAuthPlatforms',
            isShow: true,
          },
          {
            name: 'imessage-auth-users',
            title: 'imessageAuthUsers',
            isShow: true,
          },
          {
            name: 'imessage-auth-settings',
            title: 'imessageAuthSettings',
            isShow: true,
          },
        ],
      },
      {
        name: 'sandbox-messages',
        title: 'sandboxRequests',
        isShow: false,
        icon: 'icon-list',
      },
      {
        name: 'purchase',
        title: 'makePurchase',
        isShow: true,
        icon: 'icon-credit-card',
      },
      {
        name: 'billing',
        title: 'billing',
        isShow: true,
        icon: 'icon-file',
        children: [
          {
            name: 'active-purchases',
            title: 'activePurchasesTitle',
            isShow: true,
          },
          {
            name: 'balance-history',
            title: 'balanceHistory',
            isShow: true,
          },
          {
            name: 'billing-history',
            title: 'billingHistory',
            isShow: true,
          },
        ],
      },
      {
        name: 'developerTools',
        title: 'developerToolsTitle',
        isShow: true,
        icon: 'icon-sliders',
        children: [
          {
            name: 'webhook-history',
            title: 'webhookHistory',
            isShow: true,
          },
          {
            externalLink: developerDocsPage,
            title: 'developerDocsPage',
            isShow: !!developerDocsPage,
          },
        ],
      },
      {
        name: 'invitations',
        title: 'invitations',
        isShow: license.value && license.value.is_sales,
        icon: 'icon-user-plus',
        children: [
          {
            name: 'send-invite',
            title: 'sendInvite',
            isShow: true,
          },
          {
            name: 'invited',
            title: 'invited',
            isShow: true,
          },
        ],
      },
      {
        name: 'docs-and-support',
        title: 'docsAndSupport',
        isShow: showSupportAndDocsPage,
        icon: 'icon-book',
        children: [
          {
            externalLink: helpdeskPage,
            title: 'helpdeskPage',
            isShow: !!helpdeskPage,
          },
          {
            externalLink: developerDocsPage,
            title: 'developerDocsPage',
            isShow: !!developerDocsPage,
          },
          {
            externalLink: releaseNotesPage,
            title: 'releaseNotesPage',
            isShow: !!releaseNotesPage,
          },
          {
            externalLink: openSupportTicketPage,
            title: 'openSupportTicketPage',
            isShow: !!openSupportTicketPage,
          },
          {
            externalLink: openInvoiceHistory,
            title: 'openInvoiceHistory',
            isShow: !!openInvoiceHistory,
          },
          {
            externalLink: openReleaseNotes,
            title: 'openReleaseNotes',
            isShow: !!openReleaseNotes,
          },
        ],
      },
    ]
    const pathBasedSection = computed(() => submenuItems()
      .find(item => item.children && item.children.find(subItem => subItem.name === router.currentRoute.value.name)))
    const manuallyOopenedSection = computed(() => store.getters.getActiveSection)
    const toggleMenuItem = (section) => {
      store.dispatch('toggleSection', section)
    }
    return {
      menuItem,
      toggleMenuItem,
      pathBasedSection,
      manuallyOopenedSection,
      license,
      submenuItems,
      isLoad,
    }
  },
}
</script>
